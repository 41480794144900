#root{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logo{
  display: block;
  margin: 0 auto;
  width: 50vw;
  min-width: 450px;
  max-height: 8vh;
}

.text{
  text-align: center;
  font-size: x-large;
}

.button{
  text-align: center;
}

.bird{
  display: block;
  margin: 0 auto;
  width: 40vh;
}

.bird__name{
  text-align: center;
  font-size: xx-large;
  padding-top: 0;
  margin: 0;
}

.bird__txt{
  text-align: center;
  font-size: larger;
  margin-top: 0;
  padding-top: 0;
}

.left_arrow{
  position: fixed;
  top: auto;
  left: 2.5rem;
  transform: rotate(180deg);
  font-size: xx-large;
  padding: 2rem;
  cursor: url(https://unpkg.com/nes.css/assets/cursor-click.png), auto;
  -webkit-user-select: none;      
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.right_arrow{
  position: fixed;
  top: auto;
  right: 2.5rem;
  font-size: xx-large;
  padding: 2rem;
  cursor: url(https://unpkg.com/nes.css/assets/cursor-click.png), auto;
  -webkit-user-select: none;      
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.countdown, .comingsoon {
  text-align: center;
}

@media only screen and (max-width: 450px) {

  .left_arrow, .right_arrow{
    display: none;
  }

  .logo{
    width: 90vw;
    min-width: auto;
    max-height: auto;
  }

  .text, .bird__txt {
    font-size: large;
  }
}